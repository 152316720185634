import * as React from 'react';
import usePrimaryContact from 'hooks/usePrimaryContact';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { addCalendlyUserToGA, getCalendlyUrlWithoutBase } from 'utils/helpers';
import { calendlyApi } from 'utils/api';

const globalWindow = typeof window !== 'undefined' ? window : undefined;

const Calendly = () => {
  const { calendly_url } = usePrimaryContact();
  const location = useLocation();
  const queryData = queryString.parse(location?.search);
  let source = queryData?.utm_source ?? '';
  if (globalWindow) {
    if (globalWindow?.document?.referrer?.length > 0) {
      source = window.document.referrer ?? '';
    }
  }
  // useCalendlyEventListener({
  //   onEventScheduled: async (e) => {
  //     const invitee = e.data.payload.invitee.uri;
  //     const inviteeUrl = getCalendlyUrlWithoutBase(invitee);
  //     try {
  //       const eventInvitee = await calendlyApi.getEventInvitee(inviteeUrl);
  //       const inviteeName = eventInvitee.data.resource.name;
  //       const inviteeEmail = eventInvitee.data.resource.email;

  //       addCalendlyUserToGA({ userName: inviteeName, email: inviteeEmail });
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   },
  // });

  return (
    <InlineWidget
      url={calendly_url + '?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=ed1164'}
      prefill={{}}
      utm={{ utmSource: `${source}`, utmCampaign: `${location?.pathname}` }}
      styles={{ minWidth: '320px', height: '710px', overflowY: 'hidden' }}
    />
  );
};
export default Calendly;
